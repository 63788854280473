<template>
<div class="guide-page">
  <div class="module">
    <div class="title">
      哪些场景适合Excel数据导入？
    </div>
    <div class="content">
      <p>1、首次记账，需要一次性录入大量历史账单。</p>
      <p>2、其他记账平台的账单可迁移至<template v-if="!isYofish">小账管家</template><template v-else>有鱼记账</template>，
        支持随手记、<template v-if="!isYofish">有鱼记账</template>
        <template v-else>小账管家</template>、一木记账、钱迹、鲨鱼记账账单一键导入。</p>
      <p>注：该功能适用于一次性大批量迁移账单的情况，日常记账我们提供了【手动记账】、【语音记账】、【截图导入】等便捷的记账方式。</p>
    </div>
  </div>
  <div class="module">
    <div class="title">
      哪些数据可以被导入？
    </div>
    <div class="content">
      <p class="bold">1、导入文件必需包含：</p>
      <p>（1）记账时间：年-月-日，例：2017-08-01，时间可精确至时分如“2017-08-01 08:34”。</p>
      <p>（2）收支类型：仅支持<span class="red">【收入】</span>或<span class="red">【支出】</span></p>
      <p>（3）账目分类：对应<template v-if="!isYofish">小账管家</template>
        <template v-else>有鱼记账</template>中每一笔流水的分类，如：早餐，买菜等。</p>
      <p>（4）资金账户名称：每笔流水对应的资产账户，如支付宝、微信钱包、招商银行储蓄卡，
        <span class="red">需提前在APP内创建对应资产账户，否则全部默认为现金账户。</span></p>
      <p>（5）账目金额：仅支持人民币，可精确到分，无需加金钱符号</p>
      <p class="bold">2、导入文件选填项：</p>
      <p>（1）成员：若不填写，默认为“我”， 成员不存在会自动创建新成员，多个成员使用英文“,”分割，如第一条50元餐饮支出成员有“我,老婆”、则人均25元。</p>
      <p>（2）账目备注：该笔账单的备注，如:家里来亲戚买菜150元。</p>
    </div>
  </div>
  <div class="module">
    <div class="title">
      导入文件如何制作？
    </div>
    <div class="content">
      <p>1、官方模板导入：按官方模板整理需要导入的账单，便可成功导入数据至你的账本，
        <a v-if="!isYofish" href="https://download-cos.yofish.com/ad-gongjushiyebu/小账管家导入模板-自定义.xls">点击下载官方模板</a>。
        <template v-if="isYofish">
          <a v-if="isAndroid" href="https://download-cos.yofish.com/yofish-gongjushiyebu/有鱼记账导入模板-自定义.xls">点击下载官方模板</a>。
          <a v-if="isIos" href="javascript:;" @click="downloadAction">点击下载官方模板</a>。
        </template>
      </p>
      <p>2、一键导入：当前支持随手记、
        <template v-if="!isYofish">有鱼记账</template><template v-else>小账管家</template>、
        一木记账、钱迹、鲨鱼记账导出的账单文件，无须修改便可一键导入
        <template v-if="!isYofish">小账管家</template>
        <template v-else>有鱼记账</template>，其它记账软件的账单文件须按照官方模板整理后再导入。</p>
      <p>注：目前我们支持【收入】、【支出】两种形式的账单批量导入，其它交易类型如转账等敬请期待后续。</p>
    </div>
  </div>
  <div class="module">
    <div class="title">
      账单导入功能如何操作？
    </div>
    <div class="content">
      <p>1、导入文件选择：将自行整理或支持的记账平台导出的账单文件，存入到手机中，在数据导入页面点击【选择导入文件】打开文件选择器，选择要导入的文件。</p>
      <p>2、选择数据来源：例如：官方模板，表示数据来源是参照官方模板自行整理的，选择鲨鱼记账：表示数据是通过鲨鱼记账导出的。</p>
      <p>3、选择导入到哪个账本：如：日常账本、旅行账本等。</p>
      <p>4、点击确定导入：加载完成后，即会在对应账本新增刚刚导入的账单流水，返回APP【我的】点击【云同步】即可将数据拉取到手机。</p>
    </div>
  </div>
  <div class="module">
    <div class="title">
      账单导入后去哪里查看和修改？
    </div>
    <div class="content">
      <p>导入账单时我们提供了当前登录APP的账户已拥有的账本可供选择，选择账本后会按照账单时间与选中账本内现有数据进行合并，
        导入成功后，点击APP内【我的】-【云同步】将数据拉取到本机后，即可在首页查看。</p>
      <p>小妙招：如担心导入后现有账本的账单被打乱或导入后想先检查导入的账单，可先在APP内新建账本，导入时选择新账本导入，
        新账本内导入的账单检查无误后再通过
        【我的】-【<span v-if="!isYofish">数据设置</span><span v-if="isYofish">功能中心-更多</span>】-【数据迁移】，
        将新账本账单合并到正在使用的账本内。</p>
    </div>
  </div>
</div>
</template>
<script>
import { userAgentType } from '@/utils';

export default {
  name: 'RecordGuide',
  computed: {
    isYofish() {
      const { host } = window.location;
      return host.includes('yofish.com');
    },
    isAndroid() {
      return userAgentType().isAndroid;
    },
    isIos() {
      return userAgentType().isPhone;
    },
  },
  methods: {
    downloadAction() {
      window?.ios?.downloadFile('https://download-cos.yofish.com/yofish-gongjushiyebu/有鱼记账导入模板-自定义.xls');
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-page {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-bottom: 25px;
  margin: 0 25px;
  .title {
    font-weight: 700;
    font-size: 35px;
    line-height: 55px;
    margin-bottom: 15px;
  }
  .module {
    margin-bottom: 35px;
  }
  .content {
    font-size: 22px;
    line-height: 45px;
    .red {
      color: red;
    }
    .bold {
      font-size: 25px;
      font-weight: 600;
    }
    a {
      color: #1989fa;
      text-decoration: none;
    }
  }
}
</style>
